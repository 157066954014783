@charset "utf-8";

@use '~/../../scss/color' as initColor;

$primary: initColor.$primary;
$secondary: initColor.$secondary;
$tertiary: initColor.$tertiary;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;
$beige: #fffae2;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'tertiary': $tertiary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'beige': $beige
);

$enable-negative-margins: true;

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);
// scss-docs-end container-max-widths

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
  16: $spacer * 16
);

$font-size-base: 1rem; // Assumes the browser default, typically `16px`

// scss-docs-start font-sizes
$font-sizes: (
  lead: $font-size-base * 8,
  xl: $font-size-base * 4,
  1: $font-size-base * 2.5,
  2: $font-size-base * 2,
  3: $font-size-base * 1.75,
  4: $font-size-base * 1.5,
  5: $font-size-base * 1.25,
  6: $font-size-base,
  7: $font-size-base * .875,
  xs: $font-size-base * .7
);
// scss-docs-end font-sizes

// scss-docs-start position-map
$percent-values: (
  0: 0,
  1: 1%,
  2: 2%,
  3: 3%,
  4: 4%,
  5: 5%,
  6: 6%,
  7: 7%,
  8: 8%,
  9: 9%,
  10: 10%,
  11: 11%,
  12: 12%,
  13: 13%,
  14: 14%,
  15: 15%,
  16: 16%,
  17: 17%,
  18: 18%,
  19: 19%,
  20: 20%,
  21: 21%,
  22: 22%,
  23: 23%,
  24: 24%,
  25: 25%,
  26: 26%,
  27: 27%,
  28: 28%,
  29: 29%,
  30: 30%,
  31: 31%,
  32: 32%,
  33: 33%,
  34: 34%,
  35: 35%,
  36: 36%,
  37: 37%,
  38: 38%,
  39: 39%,
  40: 40%,
  41: 41%,
  42: 42%,
  43: 43%,
  44: 44%,
  45: 45%,
  46: 46%,
  47: 47%,
  48: 48%,
  49: 49%,
  50: 50%,
  51: 51%,
  52: 52%,
  53: 53%,
  54: 54%,
  55: 55%,
  56: 56%,
  57: 57%,
  58: 58%,
  59: 59%,
  60: 60%,
  61: 61%,
  62: 62%,
  63: 63%,
  64: 64%,
  65: 65%,
  66: 66%,
  67: 67%,
  68: 68%,
  69: 69%,
  70: 70%,
  71: 71%,
  72: 72%,
  73: 73%,
  74: 74%,
  75: 75%,
  76: 76%,
  77: 77%,
  78: 78%,
  79: 79%,
  80: 80%,
  81: 81%,
  82: 82%,
  83: 83%,
  84: 84%,
  85: 85%,
  86: 86%,
  87: 87%,
  88: 88%,
  89: 89%,
  90: 90%,
  91: 91%,
  92: 92%,
  93: 93%,
  94: 94%,
  95: 95%,
  96: 96%,
  97: 97%,
  98: 98%,
  99: 99%,
  100: 100%,
  auto: auto
);
// scss-docs-end position-map

$utilities: (
  'font-size': (
    responsive: true,
    rfs: true,
    property: font-size,
    class: fs,
    values: $font-sizes
  ),
  'flex-direction': (
    responsive: true,
    property: flex-direction,
    class: fd,
    values: row column row-reverse column-reverse
  ),
  'justify-content': (
    responsive: true,
    property: justify-content,
    class: jc,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      between: space-between,
      around: space-around,
      evenly: space-evenly
    )
  ),
  'align-items': (
    responsive: true,
    property: align-items,
    class: ai,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      baseline: baseline,
      stretch: stretch
    )
  ),
  'line-height': (
    responsive: true,
    property: line-height,
    class: lh,
    values: (
      1: 1,
      sm: 1.5,
      md: 2,
      lg: 2.5,
      xl: 4
    )
  ),
  'letter-spacing': (
    responsive: true,
    property: letter-spacing,
    class: ls,
    values: (
      0: 0,
      sm: .1em,
      md: .2em,
      lg: .3em,
      xl: .4em
    )
  ),
  'width': (
    responsive: true,
    property: width,
    class: w,
    values: $percent-values
  ),
  'height': (
    responsive: true,
    property: height,
    class: h,
    values: $percent-values
  ),
  'position': (
    responsive: true,
    property: position,
    values: static relative absolute fixed sticky
  ),
  'top': (
    responsive: true,
    property: top,
    values: $percent-values
  ),
  'bottom': (
    responsive: true,
    property: bottom,
    values: $percent-values
  ),
  'start': (
    responsive: true,
    property: left,
    class: start,
    values: $percent-values
  ),
  'end': (
    responsive: true,
    property: right,
    class: end,
    values: $percent-values
  ),
  'translate-middle': (
    responsive: true,
    property: transform,
    class: translate-middle,
    values: (
      null: translate(-50%, -50%),
      x: translateX(-50%),
      y: translateY(-50%),
    )
  ),
);

@import 'bootstrap/scss/bootstrap.scss';
