@charset "UTF-8";

// サイトカラー
//--------------------------------------------------
$primary: #0069a3;
$secondary: #0085a0;
$tertiary: #006870;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;
$beige: #faf5ea;
// $beige: #fffae2;

:root {
  --bs-primary: #0069a3;
  --bs-secondary: #0085a0;
  --bs-tertiary: #006870;
}
